/**
* Stylefile for the LoginComponent.
*
*/

/* Vertically Centering Container */
.flex-container {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
}

/* Bordered form */
form.login {
    /* margin: 107px 952px 71px -212px; */
    max-width: 800px;
    width: auto;
    height: auto;
}
form.changePwd {
    max-width: 800px;
    width: 463px;
    height: 450px;
}
form.resetPwd {
    max-width: 800px;
    width: 463px;
    height: auto;
}
/* Full-width inputs */
input[type='text'].login,
input[type='email'].login,
input[type='password'].login {
    width: 300px;
    height: 43px;
    padding: 8px 20px;
    margin: 8px 37px;
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e6e6e6;
    opacity: 1;
    box-sizing: border-box;
}

input[type='text'].login,
input[type='password'].login::placeholder {
    font: normal normal normal 16px/16px Siemens Sans;
    letter-spacing: 0.91px;
    color: #7c919d;
    opacity: 1;
}

/* Making the selected text field appearance also rounded */
input.login:focus {
    outline: none;
    box-shadow: 0 0 0 2px;
}

/* Set a style for all buttons */
button.login {
    color: white;
    padding: 14px 20px;
    margin: 8px 29px;
    border: none;
    cursor: pointer;
    width: 186px;
    border-radius: 25px;
    height: 50px;
}

/* Add a hover effect for buttons */
button.login:hover {
    opacity: 0.8;
}

/* Center the logo inside this container */
.imgcontainer {
    width: 162px;
    height: 56px;
    /* margin: -456px 0 -1px 104px; */
    position: relative;
    margin-inline-end: auto;
    top: -300px;
    left: 65px;
}
.boxContainer {
    width: 162px;
    height: 40px;
    /* margin: -456px 0 -1px 104px; */
    position: relative;
    top: -265px;
    left: 65px;
}
.resetBoxContainer {
    width: 162px;
    height: 40px;
    /* margin: -456px 0 -1px 104px; */
    position: relative;
    top: -300px;
    left: 65px;
}
/* Avatar image */
img.avatar {
    width: 25%;
    margin: auto;
}

/* Add padding to containers */
.login-container {
    padding-left: 5%;
    padding-bottom: 2%;
    padding-right: 5%;
    max-width: 450px;
    margin: auto;
    width: 405px;
    height: auto;
    background-color: rgba(100, 30, 140, 0.28);
}
.changePwd-container {
    padding: 16px;
    max-width: 500px;
    margin: auto;
    width: auto;
    height: 125%;
    background-color: rgba(100, 30, 140, 0.28);
}
.resetPwd-container {
    padding: 16px;
    max-width: 500px;
    margin: auto;
    width: auto;
    height: 120%;
    background-color: rgba(100, 30, 140, 0.28);
}
/* The "Forgot password" text */
span.psw {
    float: right;
    padding-top: 16px;
}

.welcomeText {
    text-align: left;
    font: normal normal normal 26px/40px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.userPhoneNo {
    text-align: left;
    font: normal normal normal 14px/17px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.infoText {
    text-align: left;
    font: normal normal normal 14px/17px Siemens Sans;
    letter-spacing: 0px;
    color: #ffff;
    opacity: 1;
    padding: 4px;
}
.welcomeEcoText {
    text-align: left;
    font: normal normal bold 28px/40px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
}

.forwardIcon {
    color: #ffffff;
    margin: -5px 5px -5px -6px;
}
.verificationCodeText {
    text-align: center;
    font: normal normal normal 14px/17px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
}
.verificationBox {
    width: 300px;
    height: 43px;
    padding: 8px 20px;
    margin: 8px 37px;
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e6e6e6;
    opacity: 1;
    box-sizing: border-box;
}

.verificationBox::placeholder {
    text-align: left;
    font: normal normal normal 16px/16px Siemens Sans;
    letter-spacing: 0px;
    color: #7c919d;
    opacity: 1;
}
.tryAgainText {
    text-align: left;
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
}

.body {
    position: relative;
    width: auto;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #641e8c;
}
.checkboxSection {
    margin-right: auto;
}

.box-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.boxAlign {
    position: absolute;
    left: 50px;
}
.welcomeText {
    text-align: justify;
    letter-spacing: 0.2px;
    color: #ffffff;
    opacity: 1;
    font: normal normal normal 20px/40px Siemens Sans;
}
.appName {
    text-align: left;
    font: normal normal bold 28px/40px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
}
.contentLogin {
    text-align: justify;
    letter-spacing: 0.2px;
    color: #1b1534;
    opacity: 1;
    font: normal normal normal 16px/16px Siemens Sans;
}
.contentLogin:hover {
    text-decoration: underline;
    text-decoration-color: #8a00e5;
    color: #1b1534;
}
.loginTitle {
    text-align: left;
    font: normal normal bold 28px/40px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
}
.forgotPwdlink {
    color: #ffffff;
    font: normal normal normal 14px/14px Siemens Sans;
    opacity: 1;
    text-decoration: underline;
    margin-left: 24%;
    text-decoration-color: #8a00e5;
    text-underline-offset: 30%;
}
.moreArrow {
    display: inline-flex;
    object-fit: none;
}
.moreLink {
    text-align: left;
    font: normal normal bold 14px/14px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-decoration: underline;
    text-decoration-color: #8a00e5;
    cursor: pointer;
    text-underline-offset: 30%;
    margin-left: 3%;
}
.box-1 {
    width: 50%;
    color: white;
    padding: 10px;
    background-color: #2e124d;
    height: 100%;
}
.box-2 {
    width: 50%;
    color: white;
}

.h-1 {
    font-size: 24px;
    font-weight: 700;
}

.text-muted {
    font-size: 14px;
}

.container .box {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    text-decoration: none;
    color: #615f5fdd;
}

.box:active,
.box:visited {
    border: 2px solid #ee82ee;
}

.box:hover {
    border: 2px solid #ee82ee;
}

.footer .p-color {
    color: #ee82ee;
}

.footer.text-muted {
    font-size: 10px;
}

.fas.fa-times {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
    background-color: #f3cff379;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fas.fa-times:hover {
    color: #ff0000;
}

@media (max-width: 767px) {
    body {
        padding: 10px;
    }

    .body {
        width: 100%;
        height: 100%;
    }

    .box-1 {
        width: 100%;
    }

    .box-2 {
        width: 100%;
        height: 440px;
    }
}
.btnPos {
    padding-left: 10px;
}
.footerText {
    position: fixed;
    padding: 5px;
    left: 60px;
    bottom: 20px;
    width: 100%;
    color: #ada9c2;
    text-align: left;
    font: normal normal normal 14px/17px Siemens Sans;
    display: inline-flex;
}
.copyRightFooter {
    text-align: end;
    padding-right: 8%;
}
.companyFootertxt {
    right: 1%;
}
.videoText {
    text-align: left;
    font: normal normal bold 14px/14px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 30%;
    text-decoration-color: #8a00e5;
    cursor: pointer;
    margin-left: 3%;
    margin-top: 23px;
}
.tutorialTxt {
    text-align: left;
    font: normal normal normal 14px/14px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.pwdfooterText {
    position: fixed;
    padding: 5px;
    left: 50px;
    bottom: 5px;
    width: 100%;
    color: #ada9c2;
    text-align: left;
    font: normal normal normal 14px/17px Siemens Sans;
}
.p-viewer {
    margin-left: 75%;
    bottom: 30px;
    position: relative;
}
.pwd1-viewer {
    position: relative;
    bottom: 30px;
    left: 92%;
}
.pwd2-viewer {
    position: relative;
    bottom: 30px;
    left: 92%;
}
.pwd3-viewer {
    position: relative;
    bottom: 34px;
    margin-left: 92%;
}
.pwd4-viewer {
    position: relative;
    bottom: 34px;
    margin-left: 92%;
}
.field-icon {
    display: inline-block;
    font: normal normal normal 25px/1 Siemens Sans;
    font-size: 1rem;
    text-rendering: auto;
    cursor: pointer;
    color: #5d596e;
}

.goBackButton {
    text-align: left;
    font: normal normal normal 14px/20px Siemens Sans;
    letter-spacing: 0px;
    color: #ae56ff;
    opacity: 1;
    margin-left: 25px;
    text-decoration: none;
}

.backwardIcon {
    color: #ae56ff;
    margin: -5px -10px -7px -22px;
}
.errorBackground {
    height: auto;
    background: #e85960 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: inline-block;
    opacity: 1;
}
.successBackground {
    height: auto;
    background: #28a745 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: inline-block;
    opacity: 1;
}

.errorMessagetext {
    text-align: left;
    font: normal normal normal 14px/17px Siemens Sans;
    letter-spacing: 0px;
    color: #f5f5f5;
    margin: 0px 0px 0px 5px;
    opacity: 1;
}
.successMessagetext {
    text-align: left;
    font: normal normal normal 14px/17px Siemens Sans;
    letter-spacing: 0px;
    color: #f5f5f5;
    margin: 0px 0px 0px 5px;
    opacity: 1;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 450px) {
    img.avatar {
        width: 75%;
        margin: auto;
    }
    span.psw {
        display: block;
        float: none;
    }
    .cancelbtn {
        width: 100%;
    }
}
