/* @import url('https://fonts.googleapis.com/css?family=Muli'); */

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Page Boder and scroll behaviour*/
html,
body,
#root,
.App {
    height: 100%;
    overflow-x: inherit;
    overflow-y: auto;
    font-family: Siemens Sans;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    font-family: Siemens Sans;
}

/* Set core body defaults */
body {
    font-family: Siemens Sans;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: Siemens Sans;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    background-color: #ffffff;
    font: normal normal normal 14px/18px Siemens Sans !important;
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    color: #424741;
    overflow: hidden;
}

/* Custom Scrollbar */

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b5b4b9;
    border-radius: 10px;
}

/* Reconfiguration of the header bar */
.HeaderContainer {
    height: 70;
    width: 100%;
    color: #000000;
}
@font-face {
    font-family: 'Siemens Sans'; /*Can be any text*/
    src: local('Siemens Sans'), url('assets/fonts/SIEMENSSANS_PROF_ROMAN.TTF') format('truetype');
}

/* Reconfiguration of  the title style on the header bar */
.HeaderTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-left: 30px;
    color: #00b300;
}

/* Reconfiguration of  the back icon style on the header bar */
.HeaderBackIconSyle {
    float: left;
    padding: 12px;
    background-color: #468c46; /* #466e78;*/
    text-decoration: none;
    font-size: 20px;
    width: 20%; /* Four equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
    text-align: right; /* If you want the text to be centered */
    border-style: none;
}

/* Reconfiguration of  the title style on the header bar */
.PanelHeaderTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    letter-spacing: 0.5px;
    margin-left: 0px;
    color: #dbdbdb;
}

.SubTitle {
    font: normal normal bold 28px/24px Siemens Sans;
    letter-spacing: 0px;
    margin-left: 15px;
    color: #1b1534;
    margin-bottom: 10px;
}
.infoTabclick {
    text-align: left;
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 0.8;
    margin-left: 15px;
}
/* Reconfiguration of the text style on pages */
.TextContent {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 25px;
    margin-left: 15px;
}
/* Reconfiguration of the title style on chart  */
.ChartItemsTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.5px;
    color: #000000;
}

/* Reconfiguration of the title style on Column chart  */
.ColumnChartItems {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    margin-left: 0;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Reconfiguration of the title style on table  */
.TableTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 10px;
}
/* Reconfiguration of the title style on cards  */
.CardTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 10px;
}

/* Reconfiguration of the subtitle style on table  */
.TableSubTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 10px;
}

/* Reconfiguration of the text style on table  */
.TableItems {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;

    margin-left: 0;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Reconfiguration of the text style on details pages */
.DetailsPageTextContent {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 10px;
}
/* Reconfiguration of the name of the product style on pages */
.LabelTextContent {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #000000;
}

/* Reconfiguration of the title style on buttons  */

.ButtonTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #000000;
}

.CardsContainer {
    background-color: #ffffff;
    /* border:0.5px solid #00b300; */
    border-color: #00b300;
    border-radius: 4px;
    cursor: 'pointer';
    min-width: 360;
    max-width: 450;
    min-height: 500px;
    max-height: 600px;
    margin-top: 10px;
}

.PieChartCardsContainer {
    background-color: #ffffff;
    /* border:0.5px solid #00b300; */
    border-color: #00b300;
    border-radius: 4px;
    cursor: 'pointer';
    min-width: 300;
    max-width: 360;
    margin-top: 10px;
}
.TableContainer {
    background-color: #ffffff;
    /* border:0.5px solid #00b300; */
    border-radius: 4px;
    cursor: 'pointer';
    /* min-width: 500px;
    max-width: 500px; */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
}
.miniCardContainer {
    display: inline-block;
    margin-right: 30;
    margin-left: 0;
    background-color: #ffffff;
}
.Separator {
    border: 5px;
    /* margin-left: 40;
    margin-right: 10; */
    margin-top: 5px;
    height: 100px;
    width: 10px;
    color: #8b8d94;
}
/* Reconfiguration of  the icon style on the side bar */
.SideBarIconStyle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ededed;
    padding-right: 100;
    margin-bottom: 3px;
    letter-spacing: 0.5px;
}

/* Reconfiguration of the logo style on sidebar  */
.TeamLogo {
    height: fit-content;
    text-align: center;
    width: fit-content;
    margin-left: 10px;
    margin-right: 10px;
}

.ActiveContainer {
    background-color: 'rgba(221, 226, 255, 0.08)';
}

.MenuContainer {
    display: 'flex';
    height: 40;
    cursor: 'pointer';
    padding-left: 16px;
}

.MenuContainer:hover {
    background-color: 'rgba(221, 226, 255, 0.08)';
}

.SideBarIconStyle:hover {
    color: #00b300;
}

.SideBarIconStyle:focus {
    background-color: #00b300;
}

.SideBarIconStyle:active {
    color: #00b300;
}

.SideBarIconStyle:visited {
    color: #00b300;
}

/* Reconfiguration of  the title style on the side bar */
.SideBarTitle {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #ededed;
    margin-left: 5px;
}
.SideBarTitle:hover {
    color: #00b300;
}

.SideBarTitle:focus {
    color: #00b300;
}

.SideBarTitle:active {
    color: #00b300;
}

.SideBarTitle::selection {
    color: #00b300;
}

/* -------------------------------------- */
.LogoImgcontainer {
    width: 88px;
    height: 50px;
    margin: 23px 0 0 23px;
}

.TitleText {
    top: 14px;
    left: 578px;
    width: 230px;
    height: 21px;
    text-align: center;
    font: normal normal bold 20px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-decoration: none;
}

.HelpButton {
    top: 17px;
    left: 1049px;
    width: 57px;
    height: 21px;
    text-align: center;
    font: normal normal normal 18px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-decoration: none;
}

.HeaderUserName {
    top: 18px;
    left: 1119px;
    width: 193px;
    height: 20px;
    text-align: left;
    font: normal normal normal 18px/28px Siemens Sans;
    letter-spacing: 0.2px;
    color: #ffffff;
    opacity: 1;
    text-decoration-line: none;
}
.HeaderIconSyle {
    font: normal normal normal 18px/28px Siemens Sans;
    letter-spacing: 0.2px;
    color: #ffffff;
    opacity: 1;
    text-decoration-line: none;
    margin-left: 15%;
    height: 20px;
}
