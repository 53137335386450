html {
    font-family: Siemens Sans;
    font-size: 14px;
}

.table {
    border: none;
}

.table-definition thead th:first-child {
    pointer-events: none;
    background: white;
    border: none;
}

.table td {
    vertical-align: middle;
}

.page-item > * {
    border: none;
}

.custom-checkbox {
    min-height: 1rem;
    padding-left: 0;
    margin-right: 0;
    cursor: pointer;
}
.custom-checkbox .custom-control-indicator {
    content: '';
    display: inline-block;
    position: relative;
    width: 30px;
    height: 10px;
    background-color: #818181;
    border-radius: 15px;
    margin-right: 10px;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    vertical-align: middle;
    margin: 0 16px;
    box-shadow: none;
}
table,
th,
td {
    border: 1px solid #ada9c2;
    vertical-align: 'middle';
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.iconView {
    object-fit: none;
}
.custom-checkbox .custom-control-indicator:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #f1f1f1;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -2px;
    top: -4px;
    -webkit-transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
    background-color: #84c7c1;
    background-image: none;
    box-shadow: none !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator:after {
    background-color: #84c7c1;
    left: 15px;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: none !important;
}

.searchInputbox {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 0.5px solid #5d596e;
    width: 20%;
    padding: 5px 7px;
    margin: 10px;
    font-size: 13px;
    border-radius: 10px;
}

.searchInputIcon {
    position: absolute;
}
