.labelBold {
    font: normal normal bold 14px/28px Siemens Sans;
}
.template {
    cursor: pointer;
    color: black;
    text-decoration: underline;
}
.infoTxt {
    font: normal normal normal 12px/14px Siemens Sans;
}
.template:hover {
    color: #8a00e5;
    background-color: transparent;
}

.session-list {
    margin-block: 16px;
}

.session-item {
    display: flex;
    align-items: center;
}
.copyTextButton {
    font-family: Siemens Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #8a00e5;
    cursor: pointer;
}
.copyButton {
    width: 187px;
    height: 40px;
    padding: 8px 16px 8px 16px;
    gap: 8px;
    border-radius: 24px 0px 0px 0px;
    opacity: 0px;
}
.generateReportButton {
    font: normal normal bold 17px/19px Siemens Sans;
    color: #8a00e5;
    cursor: pointer;
    display: inline-flex;
    float: right;
}
.topMargin {
    margin-top: 15px;
}
