.tablist {
    display: inline-block;
    padding: 8px;
    text-align: center;
    font: normal normal normal 18px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    margin-top: 10px;
}

.tabListContainer {
    width: 100%;
    height: 'auto';
}
.selectText {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    font: normal normal bold 24px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
}
.btnSelectionLayout {
    display: flex;
    align-items: flex-start;
    gap: 32px;
}
.searchbox {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 0.5px solid #5d596e;
    margin-top: 1rem;
    font-size: 13px;
    border-radius: 10px;
    outline: none;
    display: flex;
    width: 96%;
    padding: 8px;
    align-items: center;
    gap: 10px;
}

.searchIcon {
    position: absolute;
    top: 15px;
    right: 24px;
    width: 29px;
}

.container {
    position: relative;
    border-style: solid;
    border-width: thin;
    border-color: #ffffff;
}

.filterbutton {
    text-align: center;
    font: normal normal bold 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    border-radius: 12px;
    margin: 4px 0px 5px 27px;
    font-size: 13px;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.tablist.react-tabs__tab--selected {
    border-bottom: 3px solid #8a00e5;
    background-color: #f8f8f8;
}

.labelText {
    text-align: left;
    font: normal normal bold 18px/16px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    margin-top: 1rem;
    padding-bottom: 3%;
}

.expandMoreIcon {
    position: absolute;
    top: 5px;
    right: 212px;
    width: 21px;
}

.selectedFilterActive {
    background: #8a00e5;
    color: white;
}
