.emissionHeader {
    text-align: left;
    font: normal normal bold 18px/28px Siemens Sans;
    letter-spacing: 0.2px;
    color: #1b1534;
    opacity: 1;
}
.emissionTitle {
    text-align: left;
    font: normal normal normal 14px/14px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
}
.emissionLabel {
    text-align: left;
    font: normal normal bold 14px/14px Siemens Sans;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
}
.emissionCost {
    text-align: center;
    font: normal normal bold 20px/30px Siemens Sans;
    letter-spacing: 0px;
    color: #641e8c;
    opacity: 1;
}
.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.invalid-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.emissionCard {
    background-color: #fff;
}
.emissionBackground {
    background-color: #641e8c;
}
.emissionCostFont {
    text-align: center;
    font: normal normal bold 42px/24px Siemens Sans;
    color: #641e8c;
}
.textLine {
    border: 0.5px solid rgb(77, 33, 122);
}
