.headStyle{
    text-align: left;
    font: normal normal bold 28px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #1B1534;
    opacity: 0.8;
}
.fontStyle{
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px
}