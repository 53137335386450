.contentsubHead {
    text-align: left;
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 0.8;
}
.thumbnail {
    width: 100px;
    height: 80px;
}

.horizontalLine {
    border: 1px solid #ada9c2;
    opacity: 1;
}
.imgStretch {
    object-fit: none;
}
