.equipmentTitle {
    text-align: left;
    font: normal normal bold 28px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 0.8;
}

.genericInfo {
    text-align: left;
    font: normal normal bold 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
}
.imgLayout {
    border-radius: 2px;
    width: 28px;
    height: 25px;
}
.nonImgLayout {
    width: 28px;
    height: 25px;
    /* border-radius: 50%; */
}
.img-circle {
    margin-left: 15px;
}
.descriptionText {
    text-align: left;
    text-align: justify;
    text-justify: inter-word;
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
}
/* .imgBox {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ada9c2;
    border-radius: 4px;
    opacity: 1;
    width: 185px;
    height: 101px;
} */
.imgAlign {
    width: 185px;
    height: 101px;
    object-fit: contain;
}
.equipmentImg {
    width: 569px;
    height: 307px;
}
.menuBar {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #5d596e;
    opacity: 1;
    top: 345px;
    left: 979px;
    width: 345px;
    height: 41px;
    cursor: pointer;
}

.menuFont {
    text-align: left;
    font: normal normal bold 18px/28px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    padding: 5px;
}
.welcomeTxt {
    text-align: left;
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 0.8;
    padding-top: 15px;
}
.equipmentModel {
    text-align: left;
    font: normal normal bold 18px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #8a00e5;
    opacity: 1;
}
.equipmentHeadModel {
    text-align: left;
    font: normal normal bold 24px/24px Siemens Sans;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
}
.importBtn {
    text-align: right;
    letter-spacing: 0px;
    color: #8a00e5;
    opacity: 1;
}
.generatePDF {
    text-align: left;
    font: normal normal bold 17px/19px Siemens Sans;
    letter-spacing: 0px;
    color: #8a00e5;
    opacity: 1;
}
.textAlignment {
    text-align: center;
    font: normal normal bold 14px/19px Siemens Sans;
}
.MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    color: #8a00e5;
}
.chartsHeader {
    text-align: justify;
    font: normal normal bold 18px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    padding-bottom: 0.5rem;
}
.tabsHeader {
    text-align: justify;
    font: normal normal bold 18px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    padding-bottom: 0.5rem;
}
.breadCrumbSetting {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
    background-color: white;
    margin-top: 5rem;
}
.categoryTitle {
    text-align: justify;
    font: normal normal bold 16px/24px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    margin-top: 0.5rem;
}
.descriptionFont {
    text-align: justify;
    font: normal normal normal 14px/28px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    margin-top: 1.5rem;
    position: absolute;
    margin-right: 1rem;
}
.template {
    cursor: pointer;
    color: black;
    text-decoration: underline;
}
.infoTxt {
    font: normal normal normal 12px/14px Siemens Sans;
}
.template:hover {
    color: #8a00e5;
    background-color: transparent;
}
.labelBold {
    font: normal normal bold 14px/28px Siemens Sans;
}
.conclusionContent {
    text-align: left;
    font: normal normal bold 14px/17px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
}
.materialCompositionBackground {
    background: transparent radial-gradient(closest-side at 25% 52%, #502b80 0%, #070a2c 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
}
.impactassessmentBackground {
    background: transparent radial-gradient(closest-side at 25% 52%, #502b80 0%, #070a2c 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
}
.detailedimpactassessmentBackground {
    background: transparent radial-gradient(closest-side at 25% 52%, #502b80 0%, #070a2c 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
}
.tableHeadalign {
    text-align: center;
    border: none;
}
.tableDataalign {
    text-align: right;
    font: normal normal bold 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    border: none;
    border: none;
}
.tableColumnStyle {
    text-align: left;
    font: normal normal bold 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    border: none;
    /* text-indent: 10px; */
}

.categoryTableHdr {
    border: none;
    text-align: left;
    color: #4d217a;
}
.hrLine {
    /* border: 1px solid #502b80; */
    border-color: #9391aa;
    /* margin-left: 10px; */
}
.tempPos {
    margin-top: 20px;
}
.calculationBtn {
    margin-top: 1rem;
}
.selectFont {
    font-weight: bold;
}
/* .switchLabel {
    margin-top: 1rem;
} */
.toggleAlign {
    margin-top: 1.8rem;
}
.impactDetailgraph {
    margin-top: -42px;
}
.alignStar {
    z-index: 1000;
}
.alignlbl {
    margin-left: auto;
}
.unitLabel {
    font: normal normal normal 14px/14px Siemens Sans;
    color: #ffffff;
    padding-left: 10px;
    padding-top: 10px;
}
/* Reconfiguration of the subtitle style on chart  */
.ChartItems {
    font-family: Siemens Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    margin-left: 0;
    color: #000000;
}
.showallDescription {
    text-align: justify;
    font: normal normal normal 14px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #1b1534;
    opacity: 1;
    padding-top: 0.5rem;
}

.labelEquipment {
    top: 0px;
    left: 0px;
    font: normal normal bold 14px/18px Siemens Sans;
    color: #1b1534;
    padding: 10px;
    cursor: pointer;
    /* display: flex; */
}
.labelAlign {
    align-self: center;
    cursor: pointer;
}
.starImage {
    height: 20px;
    margin-bottom: 5px;
    float: right;
}
.labelEquipmentActive {
    top: 0px;
    left: 0px;
    font: normal normal bold 14px/18px Siemens Sans;
    color: white;
    padding: 10px;
    cursor: pointer;
    /* display: flex; */
}

.react-tabs__tab-list {
    flex-direction: row;
    margin: 0;
    padding: 0;
    color: black;
    display: flex;
}

.react-tabs__tab {
    list-style: none;
    padding: 8px;
    cursor: pointer;
    color: black;
    border: 2px solid #1b1534;
    /* height: 40px; */
    border-radius: 50px;
}

.react-tabs__tab--selectedTab {
    background-color: rgba(61, 15, 77, 0.1);
    border: 2px solid #3d0f4d;
    display: block;
    padding: 8px;
    border-radius: 50px;
    color: #3d0f4d;
}
.react-tabs__tab:hover {
    background-color: rgba(100, 30, 140, 0.1);
}
.read-more-less--more,
.read-more-less--less {
    color: #8a00e5;
}

.react .react-tabs__tab-panel {
    display: none;
    width: 300px;
}

.react-tabs__tab-panel--selected {
    display: flex;
}
.reactSidebarMenu {
    width: '100%';
    height: 400;
    max-width: 300;
    overflow: hidden;
}
/* .react-tabs__tab {
    padding-left: 24px;
}

.react-tabs__tab--selected {
    padding-left: 21px;
} */

.panel-content {
    text-align: center;
}

.circle-singleline {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;

    text-align: center;
    background: #808080;
    color: #fff;
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    padding: 4px;
}
/* Tooltip styling */
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #000028;
    color: #fff;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1;
    text-align: center;
    border-radius: 4px;
}
/* Dynamic horizontal centering */
[data-tooltip-position='top']:before,
[data-tooltip-position='bottom']:before {
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
/* Dynamic vertical centering */
[data-tooltip-position='right']:before,
[data-tooltip-position='left']:before {
    top: 50%;
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
[data-tooltip-position='top']:before {
    bottom: 100%;
    margin-bottom: 6px;
}
[data-tooltip-position='right']:before {
    left: 100%;
    margin-left: 6px;
}
[data-tooltip-position='bottom']:before {
    top: 100%;
    margin-top: 6px;
}
[data-tooltip-position='left']:before {
    right: 100%;
    margin-right: 6px;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position='top']:after,
[data-tooltip-position='bottom']:after {
    left: 50%;
    margin-left: -6px;
}
/* Dynamic vertical centering for the tooltip */
[data-tooltip-position='right']:after,
[data-tooltip-position='left']:after {
    top: 50%;
    margin-top: -6px;
}
[data-tooltip-position='top']:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: #000;
}
[data-tooltip-position='right']:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-right-color: #000;
}
[data-tooltip-position='bottom']:after {
    top: 100%;
    border-width: 0 6px 6px;
    border-bottom-color: #000;
}
[data-tooltip-position='left']:after {
    right: 100%;
    border-width: 6px 0 6px 6px;
    border-left-color: #000;
}
/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}
/* Basic Rules */
.switch input {
    display: none;
}
.switch {
    /* display: inline-block; */
    width: 50px;
    height: 20px;
    /* margin: 8px; */
    transform: translateY(50%);
    position: relative;
    margin-top: 6px;
}
/* Style Wired */
.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px #777, 0 0 4px #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: 0.4s;
}
.slider:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #777;
    border-radius: 30px;
    transform: translateX(-30px);
    transition: 0.4s;
}

input:checked + .slider:before {
    transform: translateX(30px);
    background: #8a00e5;
}
input:checked + .slider {
    box-shadow: 0 0 0 2px #8a00e5, 0 0 2px #8a00e5;
}

/* Style Flat */
.switch.flat .slider {
    box-shadow: none;
}
.switch.flat .slider:before {
    background: #fff;
}
.switch.flat input:checked + .slider:before {
    background: white;
}
.switch.flat input:checked + .slider {
    background: #8a00e5;
}
body {
    margin: 60px 130px;
} /* Demo purposes - ignore this margin */
