.modalBG {
    background-color: #641e8c;
}
.modalHeader {
    text-align: center;
    font: normal normal bold 22px/20px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.modalContent {
    text-align: left;
    font: normal normal normal 16px/18px Siemens Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.autoTimerLogin {
    margin-left: 7rem;
}
