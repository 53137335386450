.verticalLine {
    border-right: 1px solid;
    position: relative;
}

.equipmentFont {
    color: #3100ff;
    font-size: 18px;
    font-weight: bold;
}
.usersFont {
    color: #2e8f16;
    font-size: 18px;
    font-weight: bold;
}
.feedbackFont {
    color: #b16d11;
    font-size: 18px;
    font-weight: bold;
}
.pdfFont {
    color: #b16d11;
    font-size: 18px;
    font-weight: bold;
}
.react-tabs__tab1 {
    list-style: none;
    padding: 1%;
    flex: none;
    padding-top: 3%;
    cursor: pointer;
    color: #1b1534;
    font-size: 18px;
    border-bottom: 4px solid #9747ff;
    background-color: #f5edff;
    margin-top: 1rem;
    /* height: 40px; */
    /* border-radius: 50px; */
}
.react-tabs__tab1UnSelcted {
    list-style: none;
    flex: none;
    padding: 1%;
    padding-top: 3%;
    cursor: pointer;
    color: #1b1534;
    font-size: 18px;
    border-bottom: 1px solid #737389;
    margin-top: 1rem;
    /* height: 40px; */
    /* border-radius: 50px; */
}
.react-tabs__evalationTab {
    list-style: none;
    padding: 5px;
    cursor: pointer;
    color: black;
    border: 2px solid #1b1534;
    /* height: 40px; */
    border-radius: 50px;
}

.react-tabs__evaluation--selectedTab {
    background-color: rgba(61, 15, 77, 0.1);
    border: 2px solid #3d0f4d;
    display: block;
    padding: 5px;
    border-radius: 50px;
    color: #3d0f4d;
}
.tabAlign {
    margin-inline-start: 21.5rem;
}
.statsHeader {
    font-size: 16px;
    font-weight: bold;
    font-family: Siemens Sans;
    color: #fff;
    text-align: left;
    margin-left: 1rem;
    padding-top: 1rem;
}
.lineChartBackground {
    background: transparent radial-gradient(closest-side at 25% 52%, #502b80 0%, #070a2c 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
    margin-left: 0.1rem;
}
.barChartBG {
    background: transparent radial-gradient(closest-side at 25% 52%, #502b80 0%, #070a2c 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
    margin-left: 0.1rem;
}
.chartTitle {
    font-size: 18px;
    font-weight: bold;
    font-family: Siemens Sans;
}
.chartLayout {
    padding-bottom: 10px;
}
.chartLabel {
    font-size: 18px;
    font-family: Siemens Sans;
    font-weight: bold;
    font-family: Siemens Sans;
}
